import { Model } from 'pinia-orm';

import Folder from './Folder';
import logger from '@/utils/logger';

export interface FilePersisted extends Record<string, boolean | string> {
  id: string;
  uid: string;
  type: 'file';
  name: string;
  path: string;
  detail: string;
  editable: boolean;
  collapsed: boolean;
  createdAt: string;
  updatedAt: string;
  parentId: string;
}

export default class File extends Model {
  static entity = 'files';
  static primaryKey = 'id';

  static fields() {
    return {
      id: this.attr(null).notNullable(),
      uid: this.string('').notNullable(),

      type: this.string('file').notNullable(),
      value: this.string(''),
      name: this.string(''),
      path: this.string('').notNullable(),
      detail: this.string(''),
      editable: this.boolean(false),
      collapsed: this.boolean(false),

      createdAt: this.string(''),
      updatedAt: this.string(''),

      parentId: this.string(''),
      parent: this.belongsTo(Folder, 'parentId'),
    };
  }

  get fullPath() {
    return `${this.path}/${this.name}`.replaceAll(' ', '_');
  }

  static creating(file: File) {
    if (!file.createdAt) file.createdAt = new Date().toISOString();
  }

  static updating(file: File) {
    file.updatedAt = new Date().toISOString();
  }

  static created(file: File) {
    const { $sentry } = useNuxtApp();

    assertString(
      file.id,
      `Creating a file requires an id property, was ${file.id}`
    );
    assertString(
      file.uid,
      `Creating a file requires an uid property, was ${file.uid}`
    );

    createFirestoreRecord(file, `userFiles/${file.uid}/files/${file.id}`).catch(
      (error) => {
        logger().error('File.ts', 'createFirestoreRecord()', error);
        $sentry.captureException(error);

        // Roll back adding model
        if (useRepo(File).find(file.id) != null) {
          useRepo(File).destroy(file.id);
        }

        throw error;
      }
    );
  }

  static updated(file: File, record: unknown) {
    const { $sentry } = useNuxtApp();

    assertString(
      file.id,
      `Updating a file requires an id property, was ${file.id}`
    );
    assertString(
      file.uid,
      `Updating a file requires an uid property, was ${file.uid}`
    );

    updateFirestoreRecord(file, `userFiles/${file.uid}/files/${file.id}`).catch(
      (error) => {
        logger().error('File.ts', 'updateFirestoreRecord()', error);
        $sentry.captureException(error);

        // Roll back update
        useRepo(File).save(record as FilePersisted);

        throw error;
      }
    );
  }

  static deleted(file: File) {
    const { $sentry } = useNuxtApp();

    assertString(
      file.id,
      `Deleting a file requires an id property, was ${file.id}`
    );
    assertString(
      file.uid,
      `Deleting a file requires an uid property, was ${file.uid}`
    );

    deleteFirestoreRecord(file, `userFiles/${file.uid}/files/${file.id}`).catch(
      (error) => {
        logger().error('File.ts', 'deleteFirestoreRecord()', error);
        $sentry.captureException(error);

        // Roll back removal
        if (useRepo(File).find(file.id) == null) {
          useRepo(File).save(file);
        }

        throw error;
      }
    );
  }
}
